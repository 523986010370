<template>
    <template v-if="!readOnly">
        <ODataGrid :data-object="dsPA" hideGridMenu hideActionColumn hideMultiselectColumn disableFilterRow multilineHeader style="max-height:400px; min-height:100px"
            disableDeleteConfirm newRecordsPosition="bottom">
                <OColumn width="30" field="Prefix" headerName=" " class="text-center" />
                <OColumn width="370" field="FieldName" headerName="Item" />
                <OColumn width="206" field="EntryType" headerName="Entry Type" />
                <OColumn width="156" field="StepSubmission" headerName="GC Initial Submission" class="text-end" format="1 234.12" headerClass="text-end"
                    :editable="dsPA.current.Calculation === null && dsPA.current.CurrentStepNo === 1">
                    <template #default="{row}">
                        <span :class="{ 'editable': row.Calculation === null && row.CurrentStepNo === 1 }">{{ $formatNumber(row.StepSubmission, '1 234.12') }}</span>
                    </template>
                </OColumn>
                <OColumn width="156" field="StepRevision" headerName="GC Revised Value" class="text-end" format="1 234.12" headerClass="text-end" 
                    :editable="dsPA.current.Calculation === null && dsPA.current.CurrentStepNo === 3">
                    <template #default="{row}">
                        <span :class="{ 'editable': row.Calculation === null && row.CurrentStepNo === 3 }">{{ $formatNumber(row.StepRevision, '1 234.12') }}</span>
                    </template>
                </OColumn>
                <OColumn width="156" field="StepRecommendation" headerName="Cost Manager Recommendation" class="text-end" format="1 234.12" headerClass="text-end" 
                    :editable="dsPA.current.Calculation === null && dsPA.current.CurrentStepNo === 4">
                    <template #default="{row}">
                        <span :class="{ 'editable': row.Calculation === null && row.CurrentStepNo === 4, 'difference': row.StepRevision !== row.StepRecommendation }">{{ $formatNumber(row.StepRecommendation, '1 234.12') }}</span>
                    </template>
                </OColumn>
        </ODataGrid>
    </template>

    <template v-else>
        <table class="table small">
            <colgroup>
                <col width="35" />
                <col width="400" />
                <col width="206" />
                <col width="156" />
                <col width="156" />
                <col width="156" />
                <col width="70" />
            </colgroup>
            <thead>
                <tr>
                    <th></th>
                    <th>Item</th>
                    <th>Entry Type</th>
                    <th class="text-end">GC Initial Submission</th>
                    <th class="text-end">GC Revised Value</th>
                    <th class="text-end">Cost Manager Recommendation</th>
                </tr>
            </thead>
            <tbody>
                <template v-for="row in dsPA.data">
                    <tr>
                        <td>{{ row.Prefix }}</td>
                        <td>{{ row.FieldName }}</td>
                        <td>{{ row.EntryType }}</td>
                        <td class="text-end">{{ $formatNumber(row.StepSubmission, '1 234.12') }}</td>
                        <td class="text-end">{{ $formatNumber(row.StepRevision, '1 234.12') }}</td>
                        <td class="text-end"><span :class="{ 'difference': row.StepRevision !== row.StepRecommendation }">{{ $formatNumber(row.StepRecommendation, '1 234.12') }}</span></td>
                    </tr>
                </template>
            </tbody>
        </table>
    </template>
</template>

<style>
    div.o365-header-cell-label.text-end > span.o365-header-cell-text {
        width: 100%;
        text-align: right;
    }

    div[o365-field^='Step'].o365-body-cell:not(:has(> span.editable)) {
        background-color: whitesmoke;
    }

    span.difference {
        color: red;
    }
</style>

<script setup>
    import { onMounted, defineProps } from 'vue';
    import { getOrCreateDataObject } from 'o365-dataobject';
    
    const props = defineProps({
        itemId: Number,
        stepId: Number,
        readOnly: Boolean
    });

    onMounted(() => {
        dsPA.recordSource.whereClause = "Item_ID = " + props.itemId;
        dsPA.load();
    });

    const dsPA = getOrCreateDataObject(
    {
        "id": "dsPA",
        "viewName": "aviw_MSFT_PayApp_Items",
        "uniqueTable": "atbv_MSFT_PayApp_Items",
        "distinctRows": false,
        "distinctRows": false,
        "allowUpdate":  true,
        "allowInsert":  false,
        "allowDelete":  false,
        "appendData": true,
        "selectFirstRowOnLoad": true,
        "fields": [
            {"name": "Item_ID"},
            {"name": "AppItem_ID"},
            {"name": "StepSubmission"},
            {"name": "StepRevision"},
            {"name": "StepRecommendation"},
            {"name": "FieldName"},
            {"name": "EntryType"},
            {"name": "Name"},
            {"name": "Prefix"},
            {"name": "Calculation"},
            {"name": "SortOrder", "sortOrder": 1,"sortDirection": "asc"},
            {"name": "IsActive"},
            {"name": "CurrentStepNo"},
        ],
        "clientSideHandler": false,
        "maxRecords": 50,
        "dynamicLoading": false,
        "whereClause": null,
        "filterString": null,
        "disableAutoLoad": false,
        "optimisticLocking": false,
    });

    dsPA.on("AfterSave", () => {
        dsPA.data.forEach(row => {
            if (row['Calculation'] !== null) {
                dsPA.refreshRow(row.index);
            }
        })
    });
</script>